<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Información"></header-title>
      <v-card-title>
        <v-text-field
          class="mr-1"
          label="Buscar"
          v-model="options.search"
          append-icon="mdi-magnify"
          @input="changeSearch"
          hide-details
          single-line
          clearable
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="activities"
        :loading="loading"
        :page.sync="options.page"
        :items-per-page="options.itemsPerPage"
        hide-default-footer
        calculate-widths
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{
              (options.page - 1) * options.itemsPerPage +
              (activities.indexOf(item) + 1)
            }}
          </td>
        </template>
        <template v-slot:[`item.date_limit`]="{ item }">
          <td>
            {{ item.fecha_limite | formatdate }}
          </td>
        </template>
        <template v-slot:[`item.files`]="{ item }">
          <td>
            <span v-if="item.plantilla">
              <a
                :href="`${serve}gerencia/plantilla/${item.plantilla}`"
                target="_blank"
                >ver o descargar</a
              >
            </span>
            <span class="grey--text" v-else>sin archivo</span>
          </td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-btn
              small
              color="success"
              :loading="btn_loadinf"
              @click="showDialog(item)"
            >
              Información
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <div class="v-data-footer">
        <v-row>
          <v-col cols="12" sm="4">
            <div class="v-data-footer__select">
              Filas por página:
              <v-select
                :items="[10, 20, 30, 50, 100]"
                v-model="options.itemsPerPage"
                @change="changePerPage()"
                hide-details
                class="my-0"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="8" class="text-center">
            <v-pagination
              v-model="options.page"
              :length="options.pageCount"
              :total-visible="5"
              @input="changePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-if="idialog"
      v-model="idialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Información</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="red" dark @click="idialog = false"> Cerrar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12" sm="8">
            <h4 class="teal--text text--darken-2">Datos de la actividad</h4>
            <v-row class="mt-2">
              <v-col class="py-1" cols="12" sm="12"
                >Tipo de información:
                <span class="grey--text text--darken-2">{{
                  activity.tipo_informacion.descripcion
                }}</span></v-col
              >
              <v-col class="py-1" cols="12" sm="12"
                >Descripción:
                <span class="grey--text text--darken-2">{{
                  activity.descripcion
                }}</span></v-col
              >
              <v-col class="py-1" cols="12" sm="12"
                >Fecha límite:
                <span class="grey--text text--darken-2">{{
                  activity.fecha_limite | formatdate
                }}</span></v-col
              >
            </v-row>
          </v-col>
          <v-col cols="12" sm="4">
            <span
              :class="`text-subtitle-2 ${activity.estado ? '' : 'red--text'}`"
            >
              Actividad
              {{ activity.estado ? "vigente" : "no vigente" }}
            </span>
            <p class="grey--text text--darken-2">
              Adjuntar lo solicitado, en caso de observación debe enviar de
              nuevo
            </p>
            <v-btn
              v-if="
                (activity.estado && informations.length === 0) ||
                (activity.estado &&
                  obsPermission !== -1 &&
                  blockPermission === -1)
              "
              small
              color="success"
              @click="attachDocument()"
            >
              Adjuntar
            </v-btn>
            <v-btn class="mr-1" v-else small disabled> Adjuntar </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <h4 class="mx-4 mt-2 mb-0 pb-1 teal--text text--darken-2">
          Información remitida
        </h4>
        <v-row class="ma-1">
          <v-col cols="12">
            <div class="v-data-table theme--light">
              <div class="v-data-table__wrapper">
                <table>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Descripción</th>
                      <th class="text-left">Documento</th>
                      <th class="text-left">Fecha de envio</th>
                      <th class="text-left">Remitente</th>
                      <th class="text-left">Estado</th>
                      <th class="text-left">Revisor</th>
                      <th class="text-left">Observación</th>
                    </tr>
                  </thead>
                  <tbody v-if="informations.length > 0">
                    <tr v-for="(item, index) in informations" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.descripcion }}</td>
                      <td>
                        <a
                          :href="`${serve}gerencia/informacion/${item.documento}`"
                          target="_blank"
                          >ver o descargar</a
                        >
                      </td>
                      <td>{{ item.fecha_envio | formatfulldate }}</td>
                      <td>{{ item.remitente.nombre_completo }}</td>
                      <td>
                        <span
                          v-if="
                            item.estado_informacion.id == 1 ||
                            item.estado_informacion.id == 2
                          "
                        >
                          <v-chip small color="blue lighten-4">
                            {{ item.estado_informacion.descripcion }}
                          </v-chip>
                        </span>
                        <span v-else-if="item.estado_informacion.id == 4">
                          <v-chip small color="green lighten-4">
                            {{ item.estado_informacion.descripcion }}
                          </v-chip>
                        </span>
                        <span v-else>
                          <v-chip small color="red lighten-4">
                            {{ item.estado_informacion.descripcion }}
                          </v-chip>
                        </span>
                      </td>
                      <td>
                        {{
                          item.revisor ? item.revisor.nombre_completo : "---"
                        }}
                      </td>
                      <td>{{ item.observacion }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="text-center grey--text" colspan="9">
                        No hay datos disponibles
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rdialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Adjuntar documento</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="blue--text mb-1">
              El documento a enviar debe contar con los sellos correspondientes.
            </p>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Documento *"
                    @click="pickFile"
                    v-model="file_name"
                    append-icon="mdi-attachment"
                    :rules="[(v) => !!v || 'Archivo requerido']"
                    readonly
                  ></v-text-field>
                  <input
                    type="file"
                    style="display: none"
                    ref="template_file"
                    accept=".pdf,.odt,.ods,.doc,.docx,.xls,.xlsx,.zip"
                    @change="onFilePicked"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    type="text"
                    label="Descripción *"
                    v-model.trim="information.descripcion"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                    counter="200"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              @click.native="createInformation()"
              >Enviar</v-btn
            >
            <v-btn color="red" text @click.native="rdialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import Service from "../../services/request";
import { mapActions } from "vuex";
export default {
  name: "information-send",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      loading: false,
      btn_loading: false,
      btn_loadinf: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Tipo información ", value: "tipo_informacion.descripcion" },
        { text: "Actividad ", value: "descripcion" },
        { text: "Fecha límite ", value: "date_limit" },
        { text: "Plantilla ", value: "files", sortable: false },
        { text: "Estado ", value: "status" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      activities: [],
      informations: [],
      activity: {
        id: "",
        tipo_informacion: {},
        descripcion: "",
        fecha_limite: "",
      },
      information: {
        id: "",
        institucion_id: null,
        actividad_id: null,
        descripcion: "",
        documento: "",
        location: "",
      },
      options: {
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        rol_id: null,
      },
      idialog: false,
      rdialog: false,
      file_name: "",
      user: null,
      serve: "",
    };
  },
  computed: {
    obsPermission() {
      return this.informations.findIndex((item) => item.estado_id === 3);
    },
    blockPermission() {
      return this.informations.findIndex(
        (item) =>
          item.estado_id === 1 || item.estado_id === 2 || item.estado_id === 4
      );
    },
  },
  created() {
    this.serve = Endpoint.getServe();
  },
  mounted() {
    this.user = Storage.getUser();
    const roles = [1, 8, 11,16,17];
    if (this.user && roles.includes(this.user.rid)) {
      this.options.rol_id = this.user.rid;
      this.getActivities();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    pickFile() {
      this.$refs.template_file.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].size < 2057157) {
          this.file_name = files[0].name;
          this.information.documento = files[0];
        } else {
          this.$refs.template_file.value = "";
          this.file_name = "";
          this.information.documento = null;
          this.toast({
            color: "info",
            text: "El archivo no debe superar el peso permitido de 2 MB",
          });
        }
      } else {
        this.file_name = "";
        this.information.documento = null;
      }
    },
    getActivities() {
      this.loading = true;
      Service.svcActivityByRole("post", this.options)
        .then((response) => {
          this.loading = false;
          this.activities = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount = Math.ceil(
              response.data.count / this.options.itemsPerPage
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDialog(data) {
      this.activity = data;
      this.getInformation();
    },
    getInformation() {
      this.btn_loadinf = true;
      Service.svcInformationByActivity("post", {
        actividad_id: this.activity.id,
        institucion_id: this.user.ies,
      })
        .then((response) => {
          this.btn_loadinf = false;
          this.informations = response.data;
          this.idialog = true;
        })
        .catch(() => {
          this.btn_loadinf = false;
        });
    },
    attachDocument() {
      this.information = {
        id: "",
        institucion_id: null,
        actividad_id: null,
        descripcion: "",
        documento: "",
        location: "gerencia/informacion",
      };
      this.information.institucion_id = this.user.ies;
      this.information.actividad_id = this.activity.id;
      this.rdialog = true;
    },
    createInformation() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcInformation("post", this.information, this.file_name)
          .then((response) => {
            this.btn_loading = false;
            this.file_name = "";
            if (this.$refs.template_file) this.$refs.template_file.value = "";
            this.toast({ color: "success", text: response.data });
            this.rdialog = false;
            this.getInformation();
          })
          .catch(() => {
            this.btn_loading = false;
            console.error("Error al guardar");
          });
      }
    },

    changeSearch() {
      this.getActivities();
    },

    changePerPage() {
      this.options.page = 1;
      this.getActivities();
    },

    changePage() {
      this.getActivities();
    },
  },
};
</script>
